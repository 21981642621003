@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.configurationFormRow {
  > div > div:last-child {
    flex: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.actions {
  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
    min-width: 140px;
  }
}

.bottomBar {
  display: flex;
  justify-content: flex-end;
}

.configurationsWrapper {
  width: 100%;

  > button {
    margin-top: 8px;
  }
}

.addRow {
  @include button-reset();
  color: $gray-dark;
  align-self: start;
  height: 36px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:not(:disabled) {
    &:hover {
      color: $brand-secondary;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  > span {
    margin-right: 4px;
  }
}

.removeRow {
  @include button-reset();
  margin-top: auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-light;
  cursor: pointer;

  &:hover {
    color: $global-danger-background;
  }

  svg path:not([fill="none"]) {
    fill: currentColor;
  }
}
