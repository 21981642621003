@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.reactSelect {
  width: 100%;
  position: relative;
}

.errorMessage {
  color: #e10a14;
  font-family: $global-font-family;
  font-size: 12px;
}

.showAllFilters {
  @include button-reset();
  right: 0;
  position: absolute;
  top: 3px;
  text-decoration: underline;
  font-size: $global-xsmall-font-size;
  color: $gray-placeholder;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  &.withTooltip {
    right: 32px;
  }
}
