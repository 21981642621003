@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.selectWrapper {
  padding: 4px 8px;
  border-radius: 50px;
  box-sizing: border-box;
  display: flex;

  &.live {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzAwYTgyZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 100% 50%;
    background-size: 24px 24px;
    background-color: #e6faec;
    .select {
      color: $brand-secondary;
    }
  }

  &.offline {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6I2UxMGExNDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
      no-repeat 100% 50%;
    background-size: 24px 24px;
    background-color: #ffe0e2;

    .select {
      color: $brand-primary;
    }
  }
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 20px;

  font-size: 10px;
  font-weight: 600;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  border: none;
  height: 100%;
  width: 100%;
  background: none;
}
