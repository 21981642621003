@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.loginHeader {
  width: 84px;
  height: 25px;
  font-size: 20px;
  font-weight: bold;
  color: #1c1c1c;
}

.loginHeader {
  margin-bottom: 20px;
}

.formField {
  margin-bottom: 24px;
}

.loginForm {
  margin-top: 20px;
}

.error {
  height: 38px;
  line-height: 38px;
  background-color: #ffe0e2;
  color: #e10a14;
  font-size: 12px;
  padding-left: 12px;
}

.forgotPassword {
  text-align: right;
  font-size: 12px;
  line-height: 1.5;
  position: absolute;
  margin-left: 168px;
  cursor: pointer;
}
