@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.formGroup {
  .info {
    flex: 1 1 24%;

    .title {
      color: $gray-dark;
      > span {
        font-weight: 500;
        font-size: 15px;
      }
    }

    .description {
      color: $gray-placeholder;
      font-size: 15px;
      line-height: 1.35;
    }
  }

  .formRows {
    flex: 1 1 76%;
  }

  &.withSeparator {
    border-bottom: 1px solid $tint-gray;
  }

  @media (max-width: $breakpoint-medium-max) {
    flex-direction: column;

    .info {
      margin-bottom: 16px;
    }
  }
}
