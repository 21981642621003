@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.slugifyTextField {
  position: relative;
  width: 100%;
}

.generate {
  @include button-reset();
  align-items: center;
  justify-content: center;
  position: absolute;
  display: flex;
  height: 32px;
  width: 32px;
  top: 4px;
  right: 4px;
  border-radius: $global-border-radius;
  cursor: default;
  margin-top: 24px;

  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      background: $tint-gray-light;
    }
    &:active {
      background: darken($tint-gray-light, 5%);
    }
  }
}

.clientUrl {
  font-size: 12px;
  margin-top: 4px;
  // color: $font-color-faded;
}
