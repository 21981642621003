@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.complaints {
  margin-right: 16px;
  font-size: 14px;
  color: #999999;
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 20px;
}

.firstButton {
  margin-right: 10px;
}

.dataBlock {
  display: flex;
  box-shadow: inset 0 -1px 0 0 $tint-gray;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.label {
  flex: 0 1 34%;
  font-weight: 500;
  font-size: 15px;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.fields {
  display: flex;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex: 0 1 66%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.richEditor {
  min-height: 250px;
  // max-width: 706px;
}

.actions {
  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
    min-width: 140px;
  }
}

.slugLink {
  @include button-reset();
  display: flex;
  align-self: flex-start;
  color: $gray-placeholder;
  font-size: 12px;
  gap: 4px;
  margin-left: 16px;
  margin-top: -8px;
  word-break: break-all;

  &:hover {
    color: $gray-dark;
  }
}

.bottomBar {
  display: flex;
  justify-content: flex-end;
}
