@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  gap: 8px;
}
