@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.select {
  font-family: $global-font-family;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=)
    no-repeat 100% 50%;
  background-size: 32px 32px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0px 12px;
  padding-right: 32px;
  background-color: white;
  border-color: $tint-gray;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;

  &:focus {
    border-color: $gray-dark;
  }
}

.disabled {
  color: #999999 !important;
  background-color: #f7f7f7 !important;
  cursor: not-allowed;
}

.errorMessage {
  color: #e10a14;
  font-family: $global-font-family;
  font-size: 12px;
}
