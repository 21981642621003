@mixin hook-base-body() {
  text-rendering: geometricPrecision;
  -webkit-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: $breakpoint-small-max) {
    font-size: 15px;
  }
}

#app {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

html {
  font-family: $global-font-family;
}

a {
  text-decoration: none !important;
  font-family: Roboto;
  letter-spacing: normal;
  color: $gray-dark;
  &:hover {
    text-decoration: underline !important;
  }
}

select {
  width: 142px;
  height: 40px;
  border: solid 1px $tint-gray;
  background-color: $tint-gray;
  font-size: 14px;
  color: #222222;
  outline: none;
}

a:not([class]) {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

ul {
  padding: 0;
  margin: 0 0 24px;
  list-style: none;
  &:not([class]) {
    > li {
      position: relative;
      list-style: none;
      padding-left: 16px;
      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        top: 0.7em;
        left: 0;
        border-radius: 50%;
      }
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  &.uk-list-check {
    font-weight: 600;
    color: $gray-light;
    li {
      padding-left: 30px;
      position: relative;
      line-height: 1.29;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0.2em;
        left: 0;
        width: 13px;
        height: 6px;
        transform: rotate(-51deg);
        border-radius: 0;
        border: solid $brand-secondary;
        border-width: 0 0 2px 2px;
        background: none;
      }
    }
  }
}

ol {
  counter-reset: ol;
  padding: 0;
  margin: 0 0 24px;
  > li {
    position: relative;
    list-style: none;
    padding-left: 24px;
    &::before {
      counter-increment: ol;
      content: counter(ol) ".";
      position: absolute;
      top: 0;
      left: 5px;
      color: $brand-primary;
    }
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
}

blockquote {
  background-color: rgba(105, 190, 40, 0.05);
  color: $global-primary-background;
  padding: 16px 24px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  &::before {
    color: $global-primary-background;
    content: "\2033";
    opacity: 0.2;
    position: absolute;
    left: 8px;
    top: 9px;
    font-size: 58px;
    font-style: italic;
    line-height: 0.9;
    z-index: -1;
    @media (max-width: $breakpoint-small-max) {
      left: 1px;
      top: 6px;
    }
  }
  p {
    margin: 0;
  }
  @media (max-width: $breakpoint-small-max) {
    font-size: 15px;
    padding: 16px;
  }
}

.uk-text {
  &-small {
    @media (min-width: $breakpoint-large) {
      font-size: 16px;
    }
  }
  &-big {
    @media (min-width: $breakpoint-large) {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
    }
  }
}

.icon-svg {
  line-height: 0.5;
  display: flex;
  align-items: center;
  > div {
    width: 100%;
  }
  svg {
    width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: $global-font-family-secondary;
  margin-top: 0;
  margin-block-end: 0;
}

h1 {
  font-weight: 700;
  font-size: 20px;
}

h2 {
  font-size: 16px;
}

h3 {
  @media (max-width: $breakpoint-small-max) {
    font-size: 18px;
  }
}

h5 {
  font-weight: 600;
}

h6 {
  text-transform: uppercase;
}

.align-left {
  margin-bottom: 24px;
  @media (min-width: $breakpoint-small) {
    margin-right: 16px;
    float: left;
  }
  @media (min-width: $breakpoint-medium) {
    margin-right: 24px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
  }
}

.align-right {
  margin-bottom: 24px;
  @media (min-width: $breakpoint-small) {
    margin-left: 16px;
    float: right;
  }
  @media (min-width: $breakpoint-medium) {
    margin-left: 24px;
  }
  @media (max-width: $breakpoint-xsmall-max) {
    width: 100%;
  }
}

@mixin hook-base-blockquote() {
  @media (max-width: $breakpoint-small-max) {
    font-size: 1rem;
  }
}

.uk-container {
  &.uk-container-medium {
    max-width: $container-medium-max-width;
  }
}

@mixin wb-container {
  box-sizing: content-box;
  max-width: $container-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding-horizontal;
  padding-right: $container-padding-horizontal;
  @media (min-width: $breakpoint-small) {
    padding-left: $container-padding-horizontal-s;
    padding-right: $container-padding-horizontal-s;
  }
  @media (min-width: $breakpoint-medium) {
    padding-left: $container-padding-horizontal-m;
    padding-right: $container-padding-horizontal-m;
  }
}
