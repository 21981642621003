@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.wrapper {
  width: 100%;
}

.topBar {
  min-height: 40px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}

.actions {
  margin-left: auto;
}

.pagination {
  margin-top: 24px;
  border-top: 1px solid $tint-gray-light;
  margin-bottom: 24px;
}
