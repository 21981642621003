@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.overview {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border: 1px solid $tint-gray;
  border-radius: 4px;
}

.isForm {
  padding: 40px;
}
