@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.requestPasswordHeader {
  height: 25px;
  font-size: 20px;
  font-weight: bold;
  color: #1c1c1c;
}

.requestPasswordDescription {
  font-size: 14px;
  line-height: 1.57;
  color: #6d6d6d;
  margin-top: 16px;
}

.formField {
  margin-bottom: 24px;
}

.loginForm {
  margin-top: 20px;
}

.backToLogin {
  margin-top: 16px;
}
