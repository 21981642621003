@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.modal-navbar {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1030;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: 0.2s opacity ease-in-out;
  background: rgba(0, 0, 0, 0.35);
  &.opened {
    opacity: 1;
    visibility: visible;
  }

  &__close {
    color: $global-inverse-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    &__menu {
      margin-bottom: 39px;
      &:hover {
        color: $brand-primary;
      }
    }
    &__search {
      position: absolute;
      right: 32px;
      top: 32px;
      z-index: 1;
      &:hover {
        color: $brand-primary;
      }
    }
    > div {
      margin-right: 8px;
    }
    path {
      stroke: currentColor;
    }
    .modal-search & {
      top: 30px;
      @media (max-width: $breakpoint-small-max) {
        top: 24px;
        color: $gray-dark;
      }
      @media (max-width: $breakpoint-xsmall-max) {
        right: 24px;
      }
    }
  }

  &__open {
    padding: 0;
    border: 0;
    background: none;
    margin: 0;
  }

  &__holder {
    background: $gray-dark;
    &__menu {
      &__close {
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        transition: 0.25s all ease-in-out;
        height: 100%;
      }
      &__open {
        width: 400px;

        transition: 0.25s width ease-in-out;
      }
    }
  }

  &__container {
    position: relative;
    max-width: $container-max-width;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    @media (min-width: $breakpoint-small) {
      padding-left: 24px;
      padding-right: 24px;
    }
    @media (min-width: $breakpoint-medium) {
      padding-left: 32px;
      padding-right: 32px;
    }

    &__menu {
      padding-top: 48px;
      padding-bottom: 40px;
      height: calc(100% - 80px);
    }
  }
}
