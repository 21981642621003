@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: $global-border-radius;
  font-family: $global-font-family;
  font-size: 14px;
  padding: 15px;
  max-width: 100%;
  &:focus-within {
    @include focus-visible-pure(0);
  }
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  margin-top: 16px;

  overflow: auto;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100%;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: $global-font-family;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: $global-font-family;
  font-size: 14px;
  user-select: none;
  display: flex;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 8px;
  padding: 2px 0;
  border-radius: $global-border-radius;
  background: #f5f5f5;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: $tint-gray;
  }
}

.RichEditor-activeButton {
  color: #5890ff;
  svg path {
    fill: #5890ff;
  }
}

.urlInputContainer {
  font-family: $global-font-family;
  padding-bottom: 8px;
  position: sticky;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  input {
    margin-right: 8px;
  }
}

.RichEditor-control-wrapper {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.maxLength {
  justify-content: right;
  margin: 4px 0;
  display: inline-flex;
  font-family: $global-font-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  line-height: normal;
  text-align: right;
  width: 100%;
  display: block;
  color: rgba(0, 0, 0, 0.6);
}
