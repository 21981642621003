@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.status {
  max-width: 74px;
}

.name {
  max-width: 320px;
}

.store {
  max-width: 80px;
}

.brand {
  max-width: 80px;
}

.category {
  max-width: 100px;
}

.sponsored {
  max-width: 85px;
}

.lastEdited {
  min-width: 130px;
}

.lastSyncMoment {
  min-width: 130px;
}

.platform {
  max-width: 80px;
}

.addedBy {
  max-width: 80px;
}

.edf {
  max-width: 80px;
  display: flex;

  button {
    @include button-reset();
    font-size: inherit;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }
  }
}

.lastEdited {
  font-size: 12px;
  color: $global-muted-color;
  font-style: italic;
}

.lastSyncMoment {
  font-size: 12px;
  color: $global-muted-color;
  font-style: italic;
}
