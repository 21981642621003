@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.container {
  background: $gray-dark;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // how to do relative pathing in css?
  background-image: url("https://viper-development-images.s3-eu-west-1.amazonaws.com/blackFriday/pattern.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.center {
  min-height: 560px;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  border-radius: $global-border-radius;
  background: white;
  min-width: 370px;
  max-width: 370px;
  padding: 40px;
}
