@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.formItem {
  color: $gray-dark;
  flex: auto !important;

  &:focus-within {
    label {
      color: $gray-dark;
    }
  }

  &.isInvalid {
    label {
      color: #e10a14;
    }
    &:focus-within {
      label {
        color: $gray-dark;
      }
    }
  }
  .errors > span {
    color: #e10a14;
  }
}
