@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.DraftEditor-root {
  overflow: auto;
}

.public-DraftEditor-content {
  font-family: $global-font-family;

  ul {
    margin: 0 0 10px 25px;
    padding: 0;
    list-style: disc;
  }

  ol {
    margin-bottom: 16px;
    & > li {
      &:nth-child(n + 2) {
        margin-top: 0px;
      }

      &::before {
        color: $gray-dark;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-family: $global-font-family-secondary;
    margin-top: 0;
    margin-block-end: 0;
  }

  h1 {
    font-weight: 700;
    font-family: $global-font-family-secondary;
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
    font-family: $global-font-family-secondary;
  }

  h3 {
    @media (max-width: $breakpoint-small-max) {
      font-size: 20px;
    }
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-weight: 600;
    font-size: 16px;
  }

  h6 {
    text-transform: uppercase;
    font-size: 12px;
  }
}
