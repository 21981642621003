@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  gap: 4px;

  > div {
    animation: rotating 2s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
