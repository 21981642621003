@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.label {
  align-items: center;
  border-radius: 50px;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 6px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  min-width: 48px;
  justify-content: center;
  box-sizing: border-box;
  letter-spacing: 0.5px;
}

.live {
  background: #e6faec;
  color: darken($brand-secondary, 5%);
}

.offline {
  background: #ffe0e2;
  color: $brand-primary;
}
