@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.formActions {
  display: flex;
}

.fixed {
  position: fixed;
  bottom: -1px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  animation: slide-in 0.2s ease-out forwards;

  .wrapper {
    max-width: 1548px;
    background: white;
    padding: 16px;
    border-radius: $global-border-radius;
    border: 1px solid $tint-gray;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.025);
    justify-content: flex-end;
  }
}

.wrapper {
  display: flex;
  width: 100%;
}

.content {
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  align-items: center;

  @media (min-width: $breakpoint-medium) {
    flex-direction: row;
  }
}

.buttons {
  margin-left: auto;
  display: flex;
  gap: 8px;

  button {
    min-width: 140px;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
