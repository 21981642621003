@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.storeFilterBar {
  gap: 8px;
  display: flex;
  align-items: center;
}

.searchField {
  flex: 1 1 auto;
  position: relative;
  width: 250px;
}

.select {
  background: white;
  border-color: $tint-gray;
}
