@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.numberInput {
  display: flex;
  flex-direction: column;
}

.button {
  width: 40px;
  height: 40px;
  border: 1px solid $tint-gray;
  background-color: #fcfcfc;
  padding: 0px;
  outline: none;
  box-sizing: border-box;

  &:hover {
    background-color: darken(#fcfcfc, 5%);
  }
  &:focus {
    border-color: $gray-dark;
  }
}

.inputContainer {
  display: flex;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.plus {
  border-radius: 0px $global-border-radius $global-border-radius 0px;
}

.minus {
  border-radius: $global-border-radius 0px 0px $global-border-radius;
}

.input {
  width: 100%;
  border: solid 1px $tint-gray;
  border-radius: 4px;
  outline: none;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  text-align: left;
  display: flex;
  padding: 8px 12px;

  &.customStyle {
    text-align: center;
    border-width: 1px 0px;
    width: 64px;
    border-radius: 0;
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }
    &:focus {
      border-color: $gray-dark;
      + .plus {
        border-left: 1px solid $gray-dark;
      }
      + .minus {
        border-right: 1px solid $gray-dark;
      }
    }
  }
}

.error {
  font-size: 12px;
  margin-top: 4px;
  color: #e10a14;
}
