@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.header {
  display: none;
  &.noJsxHeader {
    h2 {
      font-size: 25px;
    }
  }

  .close {
    border-radius: $global-border-radius;

    &:not(:disabled) {
      &:hover,
      &:focus {
        background-color: $tint-gray-light;
      }
      &:active {
        background-color: darken($tint-gray-light, 2%);
      }
    }
  }
}

.hide {
  .overlay {
    opacity: 0;
  }

  opacity: 0.2;
}
