@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  gap: 8px;
}

.slugLink {
  @include button-reset();
  display: flex;
  align-self: flex-start;
  color: $gray-placeholder;
  font-size: 12px;
  gap: 4px;
  margin-top: 4px;
  word-break: break-all;

  &:hover {
    color: $gray-dark;
  }
}
