@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.fields {
  display: flex;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.form {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 24px;
}

.firstButton {
  margin-right: 10px;
}

.labelExampleFields {
  margin-top: 14px;
}
