@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
  margin-left: -8px;
}

.bottomBar {
  display: flex;
  width: 100%;
  column-gap: 8px;
  justify-content: flex-end;
}

.label {
  flex: 0 1 34%;
  font-weight: 500;
  font-size: 15px;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin: 0px 8px;
}

.description {
  margin-bottom: 29px;
  font-size: 14px;
  color: $gray;
}
