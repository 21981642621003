@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.userFilterBar {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchField {
  flex: 1 1 auto;
  position: relative;
  width: 250px;
}
