@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.settings {
  display: flex;
  height: 100%;
  position: relative;

  .settingsButton {
    @include button-reset();
    font-weight: 500;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
    margin-right: -1px;
    color: $gray-placeholder;
    svg * {
      fill: $gray-placeholder;
    }
    &:hover,
    &.active {
      color: white;
      background-color: rgba($gray, 0.3);
      cursor: pointer;
      svg * {
        fill: white;
      }
    }
  }
}

.settingsDropdown {
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid $tint-gray;
  position: absolute;
  background: white;
  top: 80px;
  right: 0;
  min-width: 94px;
  z-index: 200;
  border-radius: $global-border-radius;
}

.dropdownItem {
  @include button-reset();
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 24px;
  padding-left: 14px;
  gap: 4px;

  &.isActive {
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;
    background-color: $bg-muted;
    text-decoration: none !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $tint-gray;
  }
}
