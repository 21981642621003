@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.tooltip {
  display: flex;
  cursor: pointer;
}

.maxWidth {
  max-width: 300px;
}
