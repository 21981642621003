@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.push {
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -16px;
  margin-bottom: -16px;
  height: 35px;

  > * {
    width: 35px;
  }
}

.smallButton {
  @include button-reset();
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $global-border-radius;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: $global-border-radius;
    z-index: -1;
  }
  svg path {
    fill: $gray-lighter;
  }
}

.removeButton {
  &:hover {
    svg path {
      fill: $brand-primary;
    }
    &::before {
      background-color: lighten($brand-primary, 50%);
    }
  }
}

.editButton {
  &:hover {
    svg path {
      fill: $gray-dark;
    }
    &::before {
      background-color: $tint-gray-light;
    }
  }
}
