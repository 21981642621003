@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.resetPasswordHeader {
  height: 25px;
  font-size: 20px;
  font-weight: bold;
  color: #1c1c1c;
}

.formField {
  margin-bottom: 24px;
}

.form {
  margin-top: 20px;
}

.error {
  line-height: 1.5;
  background-color: #ffe0e2;
  color: #e10a14;
  font-size: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 12px;
}

.resetPasswordDescription {
  font-size: 14px;
  line-height: 1.57;
  color: #6d6d6d;
  margin-top: 16px;
}

.submitButton {
  margin-top: 35px;
}
