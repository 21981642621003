@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.inputField {
  width: 100%;
  height: 40px;
  border: 1px solid $tint-gray;
  border-radius: $global-border-radius;
  padding: 8px 12px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  font-family: $global-font-family;
}

.popperClassName,
.calendarClassName {
  font-family: $global-font-family !important;
}

.calendarClassName {
  border-radius: $global-border-radius !important;
  border: 1px solid $tint-gray !important;
}

.datePickerWrapper {
  width: 100%;
  display: flex;
  > div:first-child {
    display: flex;
    width: 100%;
  }

  [class*="react-datepicker__navigation"] {
    max-width: 100%;
    max-height: 100%;
    transform: rotate(0deg);

    &[class*="-icon"] {
      &::before {
        height: 6px;
        width: 6px;
        top: 10px;
      }
    }
  }
}

.error {
  margin: 4px 0;
  display: inline-flex;
  color: #e10a14;
  font-family: $global-font-family;
  font-size: 0.75rem;
  width: 100%;
}
