@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .placeholder-text {
    margin-top: 8px;
    color: #777777;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
