@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
$buttonVariants: "primary-default" $global-inverse-color $global-inverse-color,
  "primary-brand" $global-inverse-color $global-inverse-color, "primary-inverted" $gray-dark $gray-dark,
  "secondary" $gray-dark $gray-dark, "secondary-inverted" $gray-dark $gray-dark,
  "tertiary" $brand-primary (darken($brand-primary, 7%) !important),
  "tertiary-inverted" $global-inverse-color $global-inverse-color, "link-primary" $gray-dark $brand-primary,
  "link-secondary" $brand-primary (darken($brand-primary, 7%) !important),
  "link-tertiary" $brand-secondary (darken($brand-secondary, 7%) !important);

@each $name, $color, $colorHover in $buttonVariants {
  .button--#{$name}--icon-filled {
    svg path {
      fill: $color;
    }
    &:hover svg path {
      fill: $colorHover;
    }
  }

  .button--#{$name}--icon-outline {
    svg path {
      stroke: $color;
    }
    &:hover svg path {
      stroke: $colorHover;
    }
  }

  .button--#{$name}--icon-outline-fill {
    svg path {
      fill: $color;
      stroke: $color;
    }
    &:hover svg path {
      fill: $colorHover;
      stroke: $colorHover;
    }
  }
}

a.button,
a.button--primary-default,
.button,
.button--primary-default {
  font-family: $global-font-family;
  outline: none;
  margin: 0;
  border: none;
  box-sizing: border-box !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  user-select: none;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  padding: 8px 16px;
  transition: background-color 0.05s;
  cursor: pointer;
  color: $global-inverse-color;
  background-color: $brand-secondary;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &--animated {
    svg {
      transition: transform 150ms ease-in-out;
    }
    &:hover {
      svg {
        transform: translateX(4px);
      }
    }
  }

  .icon-svg {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &:hover,
  .button--primary-default:hover {
    text-decoration: none !important;
    background-color: darken($global-secondary-background, 3%);
  }

  &--disabled {
    opacity: 0.5;
    background-color: #a9a9a9;
    &:hover {
      // background-color: $brand-secondary;
      background-color: #a9a9a9;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &--primary-brand {
    background-color: $gray-dark;
    &:hover {
      background-color: lighten($gray-dark, 10%);
    }
  }

  &--primary-inverted,
  &--secondary-inverted {
    color: $gray-dark !important;
    background-color: $global-background;

    border: solid 1px $tint-gray;

    &:hover {
      background-color: #f7f7f7;
    }
    &--disabled {
      opacity: 0.5;
      background-color: transparent;
      &:hover {
        background-color: transparent;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &--secondary {
    color: $gray-dark !important;
    background-color: $tint-gray-light;
    &:hover {
      background-color: darken($tint-gray-light, 7%);
    }
  }

  &--tertiary {
    background-color: #ffe0e2;
    color: #e10a14 !important;

    &:hover {
      background-color: darken(#ffe0e2, 3%) !important;
    }

    &--disabled {
      // background-color: lighten(#ffe0e2, 3%) !important;
      opacity: 0.5;

      &:hover {
        // background-color: opacity($color: #000000) lighten(#ffe0e2, 3%) !important;
        cursor: not-allowed;
        background-color: #ffe0e2 !important;
        opacity: 0.5;
      }
    }
  }

  &--tertiary-inverted {
    color: $global-inverse-color;
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0px 2px $brand-primary;
    -moz-box-shadow: inset 0px 0px 0px 2px $brand-primary;
    box-shadow: inset 0px 0px 0px 2px $brand-primary;

    &:hover {
      -webkit-box-shadow: inset 0px 0px 0px 2px darken($brand-primary, 7%);
      -moz-box-shadow: inset 0px 0px 0px 2px darken($brand-primary, 7%);
      box-shadow: inset 0px 0px 0px 2px darken($brand-primary, 7%);
      background-color: darken($brand-primary, 35%) !important;
    }
  }

  &--FAB {
    padding: 0;
    width: 40px;
    height: 40px;
  }

  &--small {
    padding: 8px 18px;
  }

  &--link {
    &-primary,
    &-secondary,
    &-tertiary {
      &,
      &:hover {
        padding: 0;
        background: transparent;
      }
      box-shadow: none;
      font-size: 16px;
      font-weight: bold;
      font-family: $global-font-family-secondary !important;
    }

    &-primary {
      color: $gray-dark;
    }
    &-secondary {
      color: $brand-primary;

      &:hover {
        color: darken($brand-primary, 7%) !important;
      }
    }
  }
}

.icon-left {
  margin-right: 8px;
  display: inherit;
}

.icon-right {
  margin-left: 8px;
  display: inherit;
}

.button__dynamic-size {
  padding: 8px 16px !important;
  background-color: red !important;
}

.no-select {
  outline-color: transparent;
  outline-style: none;
}
