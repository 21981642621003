@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.actions {
  margin-left: auto;
  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
    min-width: 140px;
  }
}
