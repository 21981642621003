@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg path {
    &:first-child {
      &:not(:last-child) {
        fill: none;
      }
    }
  }
}

.customIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
    stroke: currentcolor;
    fill: currentcolor;
    stroke-width: 0;

    path {
      stroke: currentcolor;
      fill: currentcolor;
    }
  }
}
