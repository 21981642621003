.container {
  max-width: 1564px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: $container-medium-max-width) {
    padding: 0 16px;
  }
}

@mixin button-reset {
  box-sizing: border-box;
  font-size: 15px;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  font-family: $global-font-family;

  &:not(:disabled) {
    cursor: pointer;
  }
}

@mixin truncate-text($max-height, $amount-of-lines) {
  max-height: #{$max-height}px;
  line-height: calc(#{$max-height}px / #{$amount-of-lines});
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount-of-lines;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

@mixin focus-visible-pure($offset: 0.2rem) {
  box-shadow: none !important;
  outline-offset: #{$offset};
  outline: 0.2rem auto;
  outline-color: $gray-dark;
}

@mixin focus-visible($offset: 0.2rem) {
  &:focus-visible {
    @include focus-visible-pure($offset);
  }
}

.truncate-table {
  @include truncate-text(20, 1);
}

.table-text-faded {
  font-size: 12px;
  color: $global-muted-color;
  font-style: italic;
}

button {
  @include button-reset;
}

a,
button {
  @include focus-visible;
}

input,
select,
textarea {
  &:focus-visible {
    @include focus-visible(0);
    // box-shadow: none !important;
    // outline-offset: 0rem;
    // outline: 0.2rem auto;
    // outline-color: $gray-dark;
  }
}
