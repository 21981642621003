@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.header {
  display: flex;
  text-transform: uppercase;
  border-bottom: 1px solid $tint-gray;
  height: 36px;
  align-items: center;

  > span {
    height: 100%;
  }
}

.campaignsOverview {
  width: 100%;
  table {
    tr {
      &:hover {
        .push {
          visibility: visible;
        }
      }
    }
    td:last-child {
      padding-right: 0;
    }
  }
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.smallButton {
  width: 40px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addCampaignButton {
  margin-top: 16px;
  display: flex;
}

.checkbox {
  //Correcting checkbox code
  margin-top: -10px;
  margin-bottom: -15px;

  input {
    margin: 0;
  }
}

.description {
  margin-top: 4px;
  font-size: 14px;
  color: $gray-light;
  font-weight: 400;
  display: flex;
}

.campaignOverviewItemLink {
  display: flex;
  width: 100%;
}

.active {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;

  .tooltip {
    margin-right: 4px;
  }
}

.smallButton {
  width: 36px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-left: solid 1px $tint-gray;
  }

  &:last-child {
    border-left: solid 1px $tint-gray;
    border-right: solid 1px $tint-gray;
  }
}

.editButton {
  &:hover {
    svg path {
      stroke: $brand-secondary;
    }
  }
}

.removeButton {
  &:hover {
    svg path {
      stroke: $brand-primary;
    }
  }
}
