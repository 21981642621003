@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.categoryDetail {
  background: #f7f7f7;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 50px;
}

.topBar {
  height: 72px;
  background: #ffffff;
  box-shadow: inset 0 -1px 0 0 $tint-gray;
  z-index: 200;
  margin-bottom: 24px;
}

.topBarContainer {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topBarLeft {
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }
  > :last-child {
    margin-left: 8px;
  }
}

.editStatus {
  font-size: 12px;
  color: $global-muted-color;
  font-style: italic;
}

.topBarRight {
  display: flex;
  align-items: center;

  button:not(:last-child) {
    margin-right: 8px;
  }
}

.details {
  padding-bottom: 50px;
  background: white;
  border: 1px solid $tint-gray;
  padding: 40px;
}

.loadingSpinner {
  display: flex;
  padding-top: 64px;
  margin-bottom: 32px !important;
}
