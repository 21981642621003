@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
$animation-duration: 0.4s;

@mixin label-active() {
  font-size: 12px;
  transform: translateY(-14px);
}

@mixin absolute-width($width) {
  width: ($width);
  width: $width;
}

.form-field {
  display: flex;
  flex-direction: column;
  &:hover {
    border-color: #b9b9b9;
  }
  &__extra {
    margin: 4px 0;
    display: inline-flex;

    &__helper,
    &__error {
      color: rgba(0, 0, 0, 0.6);
      font-family: $global-font-family;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 12px;
      width: 100%;
      display: block;
    }
    &__character-counter {
      color: rgba(0, 0, 0, 0.6);
      font-family: $global-font-family;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: 12px;
      margin-top: 0;
      white-space: nowrap;
      margin-left: 8px;
    }
  }
  &__label {
    font-size: 12px;
    color: #999999;
    &__error {
      color: #e10a14;
    }
  }
  &__control {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    height: auto;
    overflow: hidden;
    will-change: opacity, transform, color;
    overflow: visible;
  }

  &__input,
  &__textarea {
    display: flex;
    padding: 8px 12px;
    z-index: 1;
    font-family: $global-font-family;
    min-height: 36px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    font-weight: 400;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    background: none;
    -webkit-appearance: none;
    background-color: #ffffff;
    border: solid 1px $tint-gray;
    border-radius: 4px;
    &:hover {
      border-color: #b9b9b9;
    }
  }
  &__textarea {
    height: 100px;
  }
  &__focused {
    .form-field {
      &__notch {
        > * {
          border-color: $gray-dark;
          border-width: 2px !important;
          text-overflow: clip;
          z-index: 10;
        }
        &-float {
          border-top: none;
          width: auto;
          > * {
            transform: translateY(-105%) scale(0.75);
            color: $gray-dark;
          }
        }
      }
    }
    .textarea {
      &__notch {
        > * {
          border-color: $gray-dark;
          border-width: 2px !important;
          text-overflow: clip;
          z-index: 10;
        }
        &-float {
          border-top: none;
          width: auto;
          > * {
            transform: translateY(-105%) scale(0.75);
            color: $gray-dark;
          }
        }
      }
    }
  }
}
.secondary {
  .form-field {
    &__notch {
      > * {
        border-color: $gray-dark !important;
      }
      &-float {
        > * {
          transform: translateY(-105%) scale(0.75);

          color: $gray-dark !important;
        }
      }
    }
  }
}

.error {
  .form-field {
    &__extra {
      display: flex;
      &__error {
        color: #e10a14 !important;
        display: block;
        width: 100%;
      }
    }
    &__notch {
      border-color: #e10a14 !important;
      > * {
        border-color: #e10a14 !important;
      }
      &-float {
        > * {
          color: #e10a14 !important;
          border-color: #e10a14 !important;
        }
      }
    }
  }
}
.not-empty {
  .form-field {
    &__notch {
      > * {
        text-overflow: clip;
        z-index: 10;
      }
      &-float {
        border-top: none;
        width: auto;
        > * {
          transform: translateY(-105%) scale(0.75);
        }
      }
    }
  }
}

.hovered {
  .form-field {
    &__notch {
      > * {
        border-width: 2px !important;
      }
    }
  }
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  z-index: 10;
  svg path {
    fill: $gray;
  }
}

.hasIcon {
  padding-left: 44px;
}

.disabled {
  color: #999999 !important;
  background-color: #f7f7f7 !important;
  cursor: not-allowed;
}
