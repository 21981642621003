@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.id {
  max-width: 74px;
}

.title {
  max-width: 160px;
}

.store {
  max-width: 160px;
}

.trackingUrl {
  max-width: 160px;
}

.platform {
  max-width: 80px;
}

.push {
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
  margin-top: -16px;
  margin-bottom: -16px;
  height: 35px;

  > * {
    width: 35px;
  }
}

.lastEdited {
  font-size: 12px;
  color: $global-muted-color;
  font-style: italic;
}
