@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.label {
  font-size: 12px;
  color: #999999;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 4px;
}

.tooltip {
  display: flex;
}

.isOptional {
  font-size: 12px;
  margin-left: 4px;
  font-style: italic;
}

.errorMessage {
  color: #e10a14;
}
