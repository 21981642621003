@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.complaints {
  font-size: 14px;
  color: $global-muted-color;
  order: 1;

  @media (min-width: $breakpoint-medium) {
    order: 0;
  }
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 20px;
}

.firstButton {
  margin-right: 10px;
}

.dataBlock {
  display: flex;
  box-shadow: inset 0 -1px 0 0 $tint-gray;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.label {
  flex: 0 1 34%;
  font-weight: 500;
  font-size: 15px;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.fields {
  display: flex;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.richEditor {
  min-height: 250px;
}
