@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.isLoading {
  tr.rows {
    opacity: 0.3;
    pointer-events: none;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(#e1e1e1, 0.3);
    }
  }
}

.table {
  thead th {
    vertical-align: middle;
    border-top: 0;
    padding: 10px 8px;
    font-weight: 500;
  }

  tbody tr {
    &:hover {
      background-color: #fcfcfc;
      cursor: pointer;

      td > div[class*="push"] {
        visibility: visible;
      }
    }
    td:has(> div[class*="push"]) {
      padding-right: 0;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid $tint-gray-light;
    }
  }

  th {
    text-align: inherit;
    font-size: 12px;
    text-transform: uppercase;
    &,
    a {
      color: $gray-light;
    }
  }

  td,
  th {
    padding: 10px 8px;
    vertical-align: top;
    border-top: #e1e1e1;
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  td {
    padding: 6px 8px;
    vertical-align: middle;
    font-size: 14px;
    &,
    a {
      color: $gray-dark;
    }
  }
}

.rows {
  td {
    height: 35px;
    min-height: 35px;
    line-height: initial;
  }
}
