@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.filterLabel {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.labelTitle {
  font-size: 12px;
  color: $gray-placeholder;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $global-border-radius;
  color: $global-inverse-color;
  font-weight: 700;
  font-size: 11px;
  height: 20px;
  text-transform: uppercase;
  padding: 0px 4px;
  line-height: 11px;
  min-width: 25px;
}

.labelText {
  font-family: $global-font-family-quaternary;
  font-style: normal;
  font-weight: 700;
  size: 11px;
  line-height: 11px;

  &.withIcon {
    margin-left: 3px;
  }
}
