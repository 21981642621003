@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.imageUpload {
  height: 121px;
  width: 376px;
  border-radius: $global-border-radius;
  border: solid 1px $tint-gray;
  background-color: #ffffff;
  cursor: pointer;

  &.dragActive {
    border: 2px dashed #999999;
  }
  &:hover {
    background: $bg-muted;
    svg {
      transform: scale(1.2);
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.imageUploadContainer {
  position: relative;

  &:hover {
    .removeIcon {
      visibility: visible;
    }
  }
}

.imageUpload {
  &:focus-visible {
    @include focus-visible(0);
  }
}

.removeIcon {
  @include button-reset();
  position: absolute;
  z-index: 5000;
  border: solid 1px $tint-gray;
  border-radius: $global-border-radius;
  width: 32px;
  height: 32px;
  background: $global-background;
  right: 8px;
  top: 32px;
  visibility: hidden;

  &:hover {
    background: lighten($brand-primary, 50%);
    border-color: lighten($brand-primary, 40%);
    svg path {
      fill: $brand-primary;
    }
  }
}
