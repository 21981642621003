@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: normal;
  color: $gray-placeholder;

  @media (max-width: $breakpoint-small) {
    width: 100%;
    justify-content: space-between;
  }
}

.list {
  display: flex;

  > * {
    color: $gray-placeholder;
    padding: 14px 14px 0 14px;
    border-top: 2px solid transparent;
    font-weight: 500;
  }
  > a {
    &:hover {
      border-top-color: darken($tint-gray, 5%);
      color: $gray;
      text-decoration: none !important;
    }
  }
}

.active {
  border-top-color: $gray-dark;
  color: $gray-dark;
}

.skip {
  display: flex;
  flex: 1 1 0%;

  > * {
    @include button-reset();
    padding: 0 8px 0 8px;
    padding-top: 14px;
    font-weight: 500;
    display: flex;
    gap: 8px;
    border-top: 2px solid transparent;
    color: $gray-lighter;

    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled) {
      &:hover {
        border-top-color: darken($tint-gray, 5%);
        color: $gray;
      }
    }
  }
}

.skipNext {
  justify-content: flex-end;
}
