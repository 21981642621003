@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.formRow {
  .formRowWrapper {
    flex-direction: column;
    .formRowItem {
      flex: 1 !important;
    }
  }
}
