@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.wrapper {
  width: calc(100% + 48px);
  padding: 0 24px 16px 24px;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid $tint-gray;
  justify-content: space-between;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 16px;
}

.editSection {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
