@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.idLabel {
  background-color: $tint-gray-light;
  padding: 2px 6px;
  border-radius: 50px;
  font-size: 12px;
}

.filterModalList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  a.filterModalItem {
    display: flex;
    gap: 8px;

    &:hover {
      text-decoration: none !important;
      > :not(.idLabel) {
        text-decoration: underline;
      }
    }
  }
}
