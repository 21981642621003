@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
$error-color: $brand-primary;
$succes-color: $brand-secondary;
$warning-color: #d49f00;
$info-color: #137cff;
$default-color: $gray-lighter;

@mixin toaster-styling($type, $background-opacity) {
  background: lighten($type, $background-opacity);
  border-color: lighten($type, 10%);
  &,
  .Toastify__close-button {
    color: $type;
  }

  .Toastify__progress-bar {
    background: $type;
  }
}

.Toastify__toast {
  border-radius: $global-border-radius;
  padding: 16px;
  min-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  .Toastify__progress-bar {
    border-radius: 0px 0px 1px 1px;
    height: 2px;
  }

  &--default {
    @include toaster-styling($default-color, 50%);
  }
  &--info {
    @include toaster-styling($info-color, 45%);
  }
  &--success {
    @include toaster-styling($succes-color, 65%);
  }
  &--warning {
    @include toaster-styling($warning-color, 55%);
  }
  &--error {
    @include toaster-styling($error-color, 50%);
  }
}
