@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.categoriesFilterBar {
  gap: 8px;
  display: flex;
  align-items: center;
}

.searchField {
  flex: 1 1 auto;
  position: relative;
}

.merchantSelect {
  width: 150px;
}
