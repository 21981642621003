@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 15px;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 13px;
  }
  &__count-item {
    color: $gray-light;
    width: 15px;
    height: 20px;
  }

  &__input {
    -webkit-appearance: none;
    background-color: white;
    border: 1px solid $tint-gray;
    border-radius: $global-border-radius;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    margin: 0 13px 0 0;
    cursor: pointer;
    &:checked {
      background-color: $gray-dark;
      border-color: $gray-dark;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NjUiIGhlaWdodD0iMTMuMTQyIiB2aWV3Qm94PSIwIDAgMTUuOTY1IDEzLjE0MiI+PHRpdGxlPmNoZWNrPC90aXRsZT48cGF0aCBkPSJNLjY3NCw3LjI5Myw1LjUyLDExLjcxNiwxNS4yMTMuNjU5IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==);
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
    }
    &:disabled {
      cursor: default;
      background-color: $tint-gray-light;
    }
  }
  &__label {
    font-size: 15px;
    font-weight: normal;
    display: flex;
    align-items: center;
    cursor: pointer;

    color: $gray-dark;
    font-size: 15px;
    line-height: 1.6;
  }
}

.noMargin {
  margin: 0px;
}
