@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.actions {
  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
    min-width: 140px;
  }
}

.bottomBar {
  display: flex;
  justify-content: flex-end;
}

.bottomBarFixed {
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  width: 100%;
  max-width: 1560px;
  padding: 16px 32px;
  border-top: 1px solid $tint-gray;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.025);
}
