@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.tabComponent {
  display: flex;
  flex-direction: column;
  height: 48px;
}

.tabs {
  display: flex;
  height: 100%;
}

.tabItem {
  @include button-reset();
  font-weight: 500;
  height: 14px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 8px;
  height: 100%;
  margin-left: 8px;
  color: #999999;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: $gray-dark;
  }
}

.isSelected {
  color: $gray-dark;
  box-shadow: inset 0 -2px 0 0 $gray-dark;
}
