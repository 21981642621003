@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.formLabel {
  .label {
    font-size: 14px;
    line-height: 160%;
    font-weight: 500;
    color: $gray-placeholder;
  }

  .optional {
    font-size: 12px;
    margin-left: 4px;
    color: $gray-placeholder;
    line-height: 160%;
  }
}
