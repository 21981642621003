@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.spinningLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px !important;
}
