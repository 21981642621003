@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.wrapper {
  width: calc(100% + 48px);
  padding: 0 24px 16px 24px;
  display: flex;
  border-bottom: 1px solid $tint-gray;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 16px;
  gap: 8px;
  align-items: center;
}

.separator {
  height: 36px;
  width: 1px;
  background-color: $tint-gray-light;
}

.amount {
  margin-right: auto;
  font-size: 14px;
  color: $gray-light;
}
