@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.isDeleted {
  opacity: 0;
  -webkit-transition: opacity 700ms linear;
  transition: opacity 700ms linear;

  svg {
    opacity: 0;
    -webkit-transition: opacity 700ms linear;
    transition: opacity 700ms linear;
  }
}

.legend {
  display: flex;
  background-color: white;
  border: 1px solid $tint-gray;
  border-radius: 4px;
  padding: 12px;
  margin-top: 8px;
  width: auto;
}

.legendItem {
  display: flex;
  margin-right: 24px;
  align-items: center;
  gap: 6px;

  .text {
    font-size: 14px;
  }
}
