@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.c-modal {
  position: relative;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: auto;
  max-height: 100vh;
  min-width: 850px;
  max-width: 850px;
  overflow: scroll;
  border-radius: $global-border-radius;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  @media (max-width: 860px) {
    width: 100% !important;
    max-height: 100vh;
    min-width: auto;
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (max-width: 460px) {
    width: 100% !important;
    max-height: 100vh;
    min-width: auto;
    margin-left: 8px;
    margin-right: 8px;
  }

  &-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
    line-height: 1;
    background: transparent;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    transition: transform 0.2s;
    outline: none !important;
    padding: 0px;
  }

  &__close-icon {
    width: 30px;
    height: 30px;
    fill: transparent;
    stroke: $gray-dark;
    stroke-linecap: round;
    stroke-width: 3;
  }

  &__body {
    padding: 35px 30px 35px 30px;
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
  background-color: $bg-muted;
  border-radius: 0px 0 0 90px;
  > * {
    margin-right: -8px;
    margin-top: -8px;
  }
  &:hover {
    background-color: darken($bg-muted, 5%);
    cursor: pointer;
  }
}

.modalContent {
  margin-top: 24px;
}
