@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
  margin-left: -8px;
}

.bottomBar {
  display: flex;
  width: 100%;
  column-gap: 8px;
  justify-content: flex-end;
}

.label {
  flex: 0 1 34%;
  font-weight: 500;
  font-size: 15px;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin: 0px 8px;
}

.fields {
  display: flex;
  width: 100%;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex: 0 1 66%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.loadingSpinner {
  display: flex;
  padding-top: 64px;
  margin-bottom: 32px !important;
}
