@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}

.firstButton {
  margin-right: 10px;
}

.deleteModal {
  margin-top: 21px;
}
.description {
  margin-bottom: 29px;
  font-size: 14px;
  color: $gray;
}
