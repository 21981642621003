@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.form {
  display: flex;
  flex-direction: column;
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.complaints {
  margin-right: 16px;
  font-size: 14px;
  color: #999999;
}

.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-top: 20px;
}

.firstButton {
  margin-right: 10px;
}

.dataBlock {
  display: flex;
  box-shadow: inset 0 -1px 0 0 $tint-gray;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.label {
  flex: 0 1 34%;
  font-weight: 500;
  font-size: 15px;
}

.formField {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.fields {
  display: flex;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  flex: 0 1 66%;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.richEditor {
  min-height: 250px;
}

.actions {
  display: flex;
  align-items: center;
  button {
    &:not(:last-child) {
      margin-right: 8px;
    }
    min-width: 140px;
  }
}

.error {
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -12px;
  color: $brand-primary;
}

.uspsLabel {
  margin-bottom: 8px;
}

.inputWithDelete {
  display: flex;
  margin-bottom: 8px;

  > div[class*="text-field"] {
    flex-grow: 1;
  }

  button {
    @include button-reset();
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-light;
    cursor: pointer;

    &:hover {
      color: $global-danger-background;
    }

    svg path:not([fill="none"]) {
      fill: currentColor;
    }
  }
}

.addUspButton {
  @include button-reset();
  color: $gray-dark;
  align-self: start;
  height: 36px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:not(:disabled) {
    &:hover {
      color: $brand-secondary;
    }
  }

  &:disabled {
    opacity: 0.5;
  }

  > span {
    margin-right: 4px;

    svg path {
      fill: currentColor;
    }
  }
}
