@import "src/shared/styles/variables.scss";
                 @import "src/shared/styles/global-style.scss";
.buttons {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 8px;
  }
}
.description {
  margin-bottom: 24px;
  font-size: 14px;
  color: $gray;
}
